import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class CurrentPlatformService {
  constructor(private readonly platform: Platform) {}

  async isPlatformReady(): Promise<boolean> {
    const res = await this.platform.ready();
    return !!res;
  }

  getCurrentPlatform(): string {
    if (this.platform.is('mobileweb') && (this.platform.is('android') || this.platform.is('ios'))) {
      return 'mobileweb';
    } else if (this.platform.is('android')) {
      return 'android';
    } else if (this.platform.is('ios')) {
      return 'ios';
    } else {
      return 'desktop';
    }
  }

  // TODO: This needs to be removed and implemented using getCurrentPlatform
  readonly isMobile: boolean = this.platform.is('ios') || (this.platform.is('android') && !this.platform.is('desktop'));
}
